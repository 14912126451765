/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import './App.css'
import SkeletonLoading from './Components/SkeletonLoading'
import EmptyView from './Components/EmptyView'
import { HomeOutlined, MenuOutlined, MobileOutlined, SaveOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Alert, Button, Divider, Drawer, Input, Layout, message, Modal, Radio, Spin, Tag, Checkbox } from 'antd';
import ListProducts from './Order/ListProducts'
import Order, { confirm, save, sendToDevice } from './Services/Order'
import FooterOrder from './Order/FooterOrder'
import SaleConditions from './Order/SaleConditions'
import { withRouter } from 'react-router-dom'
import * as Number from './Util/Number'
import profileState from './Stores/Atoms/ProfileAtom'
import { useRecoilState } from 'recoil'
import { color } from "./common";
import OrderButtons from './Components/OrderButtons';

const { TextArea } = Input;

const { Header, Content } = Layout

const App = (props) => {
  const [profile, setProfile] = useRecoilState(profileState);
  const [filter, setFilter] = useState(null)
  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState(null)
  const [fetched, setFetched] = useState(false)
  const [visible, setVisible] = useState(false)
  const [sale_condition, setSaleCondition] = useState(null)
  const [finished, setFinished] = useState(false)
  const [products, setProducts] = useState([])
  const [loading_message, setLoadingMessage] = useState(null)
  const [item_selected_condition, setItemSelectedCondition] = useState(null)
  const [duplicates, setDuplicates] = useState([]);
  const [qty_invalid, setQtyInvalid] = useState([]);
  const { settings } = profile;
  const {
    calculation_decimal_places,
    visual_decimal_places,
    enable_complement_observation,
    maximum_characters_note,
    allow_send_order_to_device,
    allow_close_order,
  } = settings;

  const [note, setNote] = useState(null);
  const [showModalSendToDevice, setShowModalSendToDevice] = useState(false);
  const [multiRemove, setMultiRemove] = useState(false);
  const [modalHintCloseOrderVisible, setModalHintCloseOrderVisible] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [prodsRemove, setProdsRemove] = useState({});

  const countCheckedItems = Object.values(checkedItems).filter((value) => value).length;
  const allProductIsChecked = countCheckedItems === (products || []).length;

  useEffect(() => {
    loadOrder();
  }, []);

  useEffect(() => {
    filterProducts();
  }, [filter]);

  useEffect(() => {
    const condition = _.find(conditions, { code: sale_condition });
    setItemSelectedCondition(condition);
  }, [sale_condition]);

  useEffect(() => {
    setDuplicates(getItemsDuplicates());
  }, [order]);

  useEffect(() => {
    setQtyInvalid(getQtyInvalid());
  }, [order]);

  const filterProducts = () => {
    const items = _.get(order, 'products', []);
    let itemsToShow = [];

    if (filter === 'all') {
      itemsToShow = items;
    } else if (filter === 'pending') {
      itemsToShow = items.filter((item) => {
        return item.status === 'pending'
      });
    } else if (filter === 'done') {
      itemsToShow = items.filter((item) => {
        return item.status === 'resolved'
      });
    }

    setProducts(itemsToShow);
  }

  const loadOrder = async () => {
    try {
      const { token } = props.match.params
      const order = await Order(token)

      if (order.status === 'revised') {
        props.history.push(`/orders/${token}/detail`, {
          order: order
        });
      }

      order.products = order.products.map((product) => {
        return getProductCalc(product);
      });
      order.id = token;

      order.amount = parseFloat(_.sumBy(order.products, 'total_value')).toFixed(visual_decimal_places);

      setOrder({
        ...order
      });
      setFetched(true);
      setFilter('pending');
      setSaleCondition(order.conditions.length ? order.condition : null);

      if (order.complement) {
        setNote(order.complement);
      }
    } catch (e) {
      setFetched(false);
    } finally {
      setLoading(false);
    }
  }

  const finish = async (isSendToDevice) => {
    if (qty_invalid.length) {
      message.warn('Verifique os itens com quantidade inválida')
      return false;
    }

    if (duplicates.length) {
      message.warn('Verifique os itens duplicados')
      return false;
    }

    if (!sale_condition) {
      message.warn('Selecione a condição de venda')
      return false;
    }

    setFinished(true);
    setLoadingMessage('Enviando pedido...');

    try {
      const { token } = props.match.params
      const { products, amount } = order

      const payload = {
        products: products.map((product) => {
          return {
            index: product.index,
            image: product.image,
            internal_code: product.internal_code,
            product_code: product.product_code,
            quantity: parseFloat(product.quantity),
            unit_value: parseFloat(product.unit_value),
            name: product.name,
            ean: product.ean,
            source: product.source,
          }
        }),
        amount: amount,
        condition: sale_condition,
        note: note,
      }

      if (isSendToDevice) {
        await sendToDevice(token, payload);
      } else {
        await confirm(token, payload);
      }

      if (note) {
        setNote('');
      }

      props.history.push(`/${token}/success`, {
        order: order
      })
    } catch (e) {
      message.error('Não foi possível enviar o pedido! Verifique os itens pendentes e tente novamente.')
    } finally {
      setFinished(false);
    }
  }

  const showDrawer = () => {
    setVisible(true);
  }

  const onClose = () => {
    setVisible(false);
  }

  const getProductCalc = (product) => {
    const quantity = product.quantity || 0;
    const unit_value = product.unit_value || 0;
    const unit_value_calc = parseFloat(parseFloat(unit_value).toFixed(calculation_decimal_places));
    const total_value = parseFloat(quantity * unit_value_calc).toFixed(calculation_decimal_places);

    return {
      ...product,
      quantity: quantity,
      unit_value: unit_value_calc.toFixed(visual_decimal_places),
      total_value: parseFloat(total_value)
    }
  }

  const handleChangeSaleCondition = (selected) => {
    setSaleCondition(selected);
  }

  const handleChangeCode = (item, code) => {
    const index = _.findIndex(order.products, { index: item.index });
    order.products[index].product_code = code;

    setOrder({
      ...order
    });
  }

  const changeQty = (item, quantity) => {
    const index = _.findIndex(order.products, { index: item.index });
    const unit_value = parseFloat(parseFloat(order.products[index].unit_value).toFixed(calculation_decimal_places))
    const total_value = quantity * unit_value;

    order.products[index].quantity = quantity;
    order.products[index].total_value = parseFloat(parseFloat(total_value).toFixed(calculation_decimal_places));
    order.amount = parseFloat(_.sumBy(order.products, 'total_value')).toFixed(visual_decimal_places)

    setOrder({ ...order });
  }

  const handleChangeQtyMinus = (item) => {
    const { products } = order;
    const index = _.findIndex(products, { index: item.index });
    let quantity = parseInt(products[index].quantity || 0);
    if (quantity > 0) {
      changeQty(item, quantity - 1);
    }
  }

  const handleChangeQtyPlus = (item) => {
    const { products } = order;
    const index = _.findIndex(products, { index: item.index });
    let quantity = parseInt(products[index].quantity || 0)
    changeQty(item, quantity + 1);
  }

  const onBlurAmount = (item) => {
    const index = _.findIndex(order.products, { index: item.index })
    const formatted_unit_value = parseFloat(item.unit_value).toFixed(visual_decimal_places);
    order.products[index].unit_value = formatted_unit_value;

    setOrder({ ...order })
  }

  const handleChangeAmount = (item, amount) => {
    const index = _.findIndex(order.products, { index: item.index });
    const product = order.products[index];
    const quantity = product.quantity;
    const unit_value = parseFloat(parseFloat(amount).toFixed(calculation_decimal_places));
    const total_value = quantity * unit_value;

    order.products[index].unit_value = amount
    order.products[index].total_value = parseFloat(parseFloat(total_value).toFixed(calculation_decimal_places));
    order.amount = parseFloat(_.sumBy(order.products, 'total_value')).toFixed(visual_decimal_places);

    setOrder({ ...order })
  }

  const onChangeProductSuggest = (item, newItem) => {
    const index = _.findIndex(order.products, { index: item.index });
    order.products[index] = {
      ...order.products[index],
      ean: newItem.ean,
      product_code: newItem.product_code,
      name: newItem.name,
      source: newItem.source,
      image: newItem.image,
    }

    setOrder({ ...order });
    filterProducts();
  }

  const onChangeFilter = (e) => {
    e.preventDefault()

    setFilter(e.target.value)
  }

  const getTotalForFilter = (status) => {
    const products = _.get(order, 'products', [])

    if (products && status === 'pending') {
      return products.filter(function (item) {
        return item.status === 'pending'
      }).length
    } else if (products && status === 'done') {
      return products.filter(function (item) {
        return item.status === 'resolved'
      }).length
    } else {
      return products.length
    }
  }

  const onSave = async () => {
    try {
      const { token } = props.match.params
      const { products } = order

      const payload = {
        condition: sale_condition,
        products: products.map((product) => {
          return {
            ...product,
            index: product.index,
            internal_code: product.internal_code,
            product_code: product.product_code,
            quantity: product.quantity,
            unit_value: product.unit_value,
            name: product.name,
            ean: product.ean,
            source: product.source,
          }
        })
      }

      await save(token, payload)
      message.success('Pedido salvo!')
    } catch (e) {
      message.error('Não foi possível salvar o pedido!')
    }
  }

  const onCheckProduct = (item) => {
    const { index } = item || {};

    setCheckedItems((prev) => {
      const isSelected = prev[index] || false;
      prev[index] = !isSelected;
      return { ...prev };
    })
  }

  const closeChoiceMultipleProdsRemove = () => {
    setMultiRemove(!multiRemove);
    setCheckedItems({});
    setProdsRemove([])
  }

  const choiceMultipleProdsRemove = () => {
    closeChoiceMultipleProdsRemove()
  }

  const onSaveSelectedProdsRemove = () => {
    closeChoiceMultipleProdsRemove()

    prodsRemove.forEach((item) => {
      changeQty(item, 0);
      if (!item.product_code) {
        handleChangeCode(item, '0');
      }
    });
  }

  const onClickItemDuplicated = () => {
    setFilter('all');
  };

  const onClickItemQtyInvalid = (prod) => {
    const status = prod.status === 'pending' ? 'pending' : 'done';
    setFilter(status);

    setTimeout(() => {
      const element = document.getElementById(`quantity-${prod.index}`)
      if (element) {
        element.focus();
      } else {
        setTimeout(() => {
          const element = document.getElementById(`quantity-${prod.index}`)
          if (element) {
            element.focus();
          }
        }, 300);
      }
    }, 200);
  };

  const getItemsDuplicates = () => {
    const prods = (order || {}).products || [];
    const items = _.filter(prods, (prod) => {
      return prod.product_code && prod.product_code != 0
        && _.filter(prods, (current) => {
          const qty = current.quantity;
          return prod.product_code === current.product_code && qty > 0;
        }).length > 1;
    });

    return _.uniqBy(items, 'product_code')
  }

  const onChangeProductsToRemove = (item = {}, type = '') => {
    switch(type) {
      case 'add':
        setProdsRemove(prev => ([...prev, item]));
        break;
      case 'remove':
        setProdsRemove(prev => prev.filter((itemRemove) => itemRemove !== item));
        break;
      default:
        console.warn('onChangeProductsToRemove: method not supported');
    }

    onCheckProduct(item);
  }

  const selectAllProducts = (e) => {
    const isCheck = e.target.checked;
    const selectProducts = {};

    if(isCheck) {
      (products || []).forEach(({ index }) => {
        selectProducts[index] = true;
      });
    }
    
    setProdsRemove(isCheck ? products : [])
    setCheckedItems(selectProducts);
  }

  const getQtyInvalid = () => {
    const prods = (order || {}).products || []
    const items = _.filter(prods, (prod) => {

      const qty = prod.quantity;
      try {
        if (qty == parseInt(qty, 10) && qty >= 0)
          return false;
      } catch (e) {
      }

      return prod.product_code != 0;
    });

    return _.uniqBy(items, 'product_code')
  }

  const { conditions, company, seller, customer } = order || {};

  return (
    <Layout style={{ paddingBottom: '40px' }}>
      <Header className="header">
        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline',
          marginLeft: '-30px'
        }}>
          <MenuOutlined
            onClick={showDrawer}
            style={{ color: '#fff' }}
            width={'4em'}
            height={'4em'} />
          <h1 style={{
            marginLeft: 10
          }}>
            {!order ? 'Pedido EDI' : order.reference}
          </h1>
        </div>
        <div>
          {!multiRemove &&
            <Button shape="round" icon={<SaveOutlined />} onClick={onSave.bind(this)}>
              Salvar
            </Button>
          }
        </div>
      </Header>
      <Content>
        <Spin tip={loading_message || 'Enviando pedido...'} spinning={finished}>
          {
            fetched && <Drawer
              title={company.name}
              placement="left"
              closable={true}
              onClose={onClose}
              visible={visible}>
              <Button
                type="link"
                icon={<HomeOutlined />}
                onClick={function () {
                  props.history.push(`/`)
                }}>
                Ir ao início
              </Button>
              <Divider />
              <p style={{
                fontWeight: 'bold'
              }}>Você:</p>
              <p>{seller.name}</p>
              <p>{seller.nin}</p>
              <Divider />
              <p style={{
                fontWeight: 'bold'
              }}>Cliente:</p>
              <p>{customer.name}</p>
              <p>{customer.nin}</p>
            </Drawer>
          }

          {fetched && <SaleConditions
            items={conditions}
            itemSelected={item_selected_condition}
            selected={sale_condition}
            handleChangeSaleCondition={handleChangeSaleCondition} />}

          {order && order.error_messages ?
            order.error_messages.map((message, i) => {
              return (
                <span key={i}>
                  <Alert message={message} type="error" showIcon />
                </span>)
            }) : null
          }

          {
            order && (
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                margin: 10,
              }}>
                <div>
                  <span style={{
                    fontWeight: '600'
                  }}>Página </span><span>{order.page} </span>
                </div>
                <div>
                  <span style={{
                    fontWeight: '600',
                    marginLeft: 10
                  }}>PDF ID </span><span>{order.pdf_id}</span>
                </div>
              </div>
            )
          }

          {
            duplicates.length ?
              <div style={{
                margin: 20
              }}>
                <h3 style={{ margin: '10px 0' }}>Produtos duplicados:</h3>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}>
                  {
                    duplicates.map((duplicate, i) => {
                      return (
                        <div key={i}>
                          <Tag color="#f50"
                            style={{
                              marginTop: -2,
                            }}
                            onClick={() => {
                              onClickItemDuplicated(duplicate);
                            }}>
                            #{duplicate.product_code}
                          </Tag>

                        </div>
                      )
                    })
                  }
                </div>
              </div>
              :
              null
          }

          {
            qty_invalid.length ?
              <div style={{
                margin: 20
              }}>
                <h4 style={{ margin: '16px 0' }}>Produtos com quantidade inválida:</h4>
                <div>
                  {
                    qty_invalid.map((invalid, i) => {
                      return (
                        <Tag color="#f50"
                          key={i}
                          onClick={() => {
                            onClickItemQtyInvalid(invalid);
                          }}>
                          #{invalid.product_code}
                        </Tag>
                      )
                    })
                  }
                </div>
              </div>
              :
              null
          }

          {!multiRemove && <div style={{
            margin: '10px 10px',
            textAlign: 'center'
          }}>
            <Radio.Group
              size={'medium'}
              buttonStyle="solid"
              value={filter}
              onChange={onChangeFilter}
              style={{
                width: '100%'
              }}>
              <Radio.Button value="pending">Pendentes ({getTotalForFilter('pending')})</Radio.Button>
              <Radio.Button value="done">Resolvidos ({getTotalForFilter('done')})</Radio.Button>
              <Radio.Button value="all">Todos</Radio.Button>
            </Radio.Group>
          </div>}

          {Boolean(products && products.length) &&
            <div style={{ position: 'sticky', top: '0px', background: '#f0f2f5', zIndex: 9, paddingBottom: '6px' }}>
              {!multiRemove ?
                <Button size="large" type="link" onClick={choiceMultipleProdsRemove.bind(this)}>
                  Selecionar produtos
                </Button>
                :
                (
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px'}}>
                    <span>
                      <Button
                        size="large"
                        type="text"
                        style={{'padding': '0px 4px'}}
                        icon={<CloseOutlined />}
                        onClick={choiceMultipleProdsRemove.bind(this)}
                      ></Button>
                      {countCheckedItems} item(s) selecionado(s)
                    </span>

                    <Checkbox 
                      style={{paddingLeft: '11px'}}
                      checked={allProductIsChecked}
                      indeterminate={!allProductIsChecked && countCheckedItems} 
                      onChange={selectAllProducts}
                    > 
                      Marcar todos
                    </Checkbox>
                  </div>
                )}
            </div>}

          <div>
            <SkeletonLoading loading={loading} />

            {fetched && products && products.length ?
              <ListProducts
                onChangeProductSuggest={onChangeProductSuggest}
                handleChangeCode={handleChangeCode}
                duplicates={duplicates}
                changeQty={changeQty}
                order={order}
                visual_decimal_places={visual_decimal_places}
                handleChangeQtyPlus={handleChangeQtyPlus}
                handleChangeQtyMinus={handleChangeQtyMinus}
                handleChangeAmount={handleChangeAmount}
                onBlurAmount={onBlurAmount}
                status={filter}
                original_items={_.get(order, 'original_content.itens', [])}
                items={products}
                multiRemoveProds={multiRemove}
                checkedItems={checkedItems}
                onChangeProductsToRemove={onChangeProductsToRemove}
              /> : null}

            {!loading && products && !products.length ? <EmptyView /> : null}

            {!loading && !fetched ? <EmptyView /> : null}

            {fetched ? <FooterOrder total={Number.formatBRL(order.amount, visual_decimal_places)}
              count={order.products.length} /> : null}

            {!loading && fetched && enable_complement_observation ? (
              <div
                style={{
                  textAlign: "center",
                  margin: 20,
                }}
              >
                <label htmlFor="obs">Observação:</label>
                <TextArea
                  id="obs" name="obs" rows="5" cols="33"
                  value={note}
                  maxLength={maximum_characters_note || 100}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                  showCount={true}
                  placeholder="Digite aqui uma observação para o pedido..." />
              </div>
            ) : null}

            {!loading && fetched && !multiRemove ? (
              <OrderButtons
                loading={finished}
                allowCloseOrder={allow_close_order}
                allowSendOrder={allow_send_order_to_device}
                onFinish={finish}
                showModalFeature={() => setModalHintCloseOrderVisible(true)}
                showModalSend={() => setShowModalSendToDevice(true)}
              />
            ) : null}
          </div>
        </Spin>
      </Content>

      <Modal
        title="Deseja realmente enviar o pedido?"
        visible={showModalSendToDevice}
        cancelText={'Cancelar'}
        okText={'Enviar'}
        onOk={() => {
          finish(true);
          setShowModalSendToDevice(false);
        }}
        onCancel={() => {
          setShowModalSendToDevice(false);
        }}>
        <p>O pedido será enviado para o Força de vendas como orçamento!</p>
      </Modal>

      <Modal
        title="Tenha uma experiência completa"
        visible={modalHintCloseOrderVisible}
        okText={'Ok'}
        onCancel={() => {
          setModalHintCloseOrderVisible(false);
        }}
        onOk={() => {
          setModalHintCloseOrderVisible(false);
        }}>
        <p>Desbloqueie esta funcionalidade e processe centenas de pedidos com apenas alguns cliques.</p>
      </Modal>

      {multiRemove && <div style={{ position: 'relative', display: 'flex' }}>  
        <Button
          size="large"
          type="primary" 
          style={{ bottom: '0px', position: 'fixed', width: '100%' }}  
          onClick={onSaveSelectedProdsRemove.bind(this)}>
            Remover itens selecionados
        </Button>
      </div>}
    </Layout>
  );
}

export default withRouter(App)
