import React, { useEffect, useState } from 'react'
import './App.css';
import { CloseOutlined, UploadOutlined, } from '@ant-design/icons';
import _ from 'lodash';
import { Button, Empty, Layout, message, Modal, Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { FlatList, View } from 'react-native-web';
import Header from './Components/Header';
import { color } from './common/';
import { list, upload } from './Services/Document'
import FloatingButton from './Components/FloatingButton'
import DocumentItem from './Screens/Documents/DocumentItem'
import DocumentItemExpanded from './Screens/Documents/DocumentItemExpanded'
import ModalSendPDF from './Screens/Documents/ModalSendPDF'
import { cancel, cancelMultiple, save } from './Services/Order'
import { hideToolbar } from './Services/Auth'
import { ORDER_STATUS } from "./Util";

const { Content } = Layout

export const STATUS = {
  PENDENT: 1,
  APPROVED: 2,
  REJECTED: 3,
}

const Documents = (props) => {
  const [expanded, setExpanded] = useState({})
  const [documents, setDocuments] = useState([])
  const [loading, setLoading] = useState(true)
  const [show_modal, setShowModal] = useState(false)
  const [document_selected, setDocumentSelected] = useState(null)
  const [current_item_delete, setCurrentItemDelete] = useState(null);
  const [open_modal, setOpenModal] = useState(false);
  const [editing, setEditing] = useState({});
  const [processingPdf, setProcessingPdf] = useState(false);
  const [progressFile, setProgressFile] = useState(0);
  const [multiCancel, setMultiCancel] = useState(false);
  const [checkedOrders, setCheckedOrders] = useState({})
  const [loadingCancelOrders, setLoadingCancelOrders] = useState(false);

  const countCheckedOrders = Object.values(checkedOrders)
        .reduce((acc, current) => acc + Object.values(current).filter(value => value).length, 0);

  useEffect(() => {
    loadDocuments()
  }, [])

  const handleOk = async () => {
    try {
      await cancel(current_item_delete.id);
      await refresh();

      setOpenModal(false);

      message.success('Pedido cancelado com sucesso!')
    } catch (e) {
      message.error('Não foi possível cancelar o pedido! Tente novamente!')
    }
  }

  const handleCancel = () => {
    setOpenModal(false);
  }

  const onClickOrder = (item) => {
    const { status, temp_order_number } = item;

    if (item.reference !== 'INEXISTENTE') {
      if ((status === ORDER_STATUS.REVISED || status === ORDER_STATUS.SENT_TO_DEVICE) && !!temp_order_number) {
        props.history.push(`/orders/${item.id}/detail`);
        return;
      }

      if ([
        ORDER_STATUS.SENT_TO_DEVICE,
        ORDER_STATUS.REVISED,
        ORDER_STATUS.CANCELED
      ].indexOf(status) === -1) {
        props.history.push(`${item.id}`)
      }
    }
  }

  const onSavingOrderNumber = async (item) => {
    try {
      const reference = window.document.getElementById(`reference-${item.id}`).value;
      if (reference) {
        const token = item.id;
        const payload = {
          reference: reference,
          products: item.products,
        }

        await save(token, payload);
        onEditingNumberOrder(item);
        message.success('Pedido salvo!');

        props.history.push(`${item.id}`);
      } else {
        message.warning('Informe o número do pedido')
      }
    } catch (e) {
      message.error('Não foi possível salvar o pedido!')
    }
  }

  const onEditingNumberOrder = (item) => {
    editing[item.id] = !editing[item.id];

    setEditing({
      ...editing
    });

    if (editing[item.id]) {
      setTimeout(() => {
        document.getElementById(`reference-${item.id}`).focus();
      }, 200);
    }
  }

  const showModal = (item, evt) => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();

    setOpenModal(true);
    setCurrentItemDelete(item);

    return true;
  }

  const loadDocuments = async () => {
    try {
      const data = await list();
      setDocuments(data);
    } catch (e) {
      message.error('Não foi possível carregar os documentos!');
    } finally {
      setLoading(false);
    }
  }

  const refresh = async () => {
    setLoading(true)
    await loadDocuments()
  }

  const onClickSelectOrders = () => {
    setMultiCancel(prev => !prev);
    setCheckedOrders({});
  }

  const onCheckOrder = (documentId, item) => {
    const { id } = item || {};

    if(!id || !documentId) {
      return
    }

    setCheckedOrders(prev => {
      const existDocument = !!prev[documentId];

      if(!existDocument) {
        prev[documentId] = {};
      }

      const isChecked = prev[documentId][id];

      prev[documentId][id] = !isChecked;

      return {...prev};
    });
  }

  const onCheckAllOrders = (e, document = {}) => {
    const { id: documentID, orders = [] } = document || {};
    const countAllOrders = (orders || []).filter((order) => order.status !== 'canceled').length;
    const isCheck = e.target.checked;
    const hashTableOrders = {}
    const documentOrders = checkedOrders[documentID] || {};

    if(isCheck && countAllOrders !== Object.values(documentOrders).filter((value) => value).length) {
      (orders || []).forEach((item) => {
        if(item.status !== 'canceled') {
          hashTableOrders[item.id] = true;
        }
      });
    }

    setCheckedOrders(prev => ({...prev, [documentID]: hashTableOrders}));
  }

  const beforeCancelMulti = () => {
    setLoadingCancelOrders(true);
  }

  const onSuccessCancelMulti = (response) => {
    setMultiCancel(false);
    setCheckedOrders({});
    refresh();
  }

  const onFinnalyCancelMulti = () => {
    setLoadingCancelOrders(false);
  }

  const onSaveCancelMultiOrders = () => {
    if(countCheckedOrders === 0) {
      message.warn('Selecione o(s) pedido(s) que deseja cancelar');
    }

    const ordersIds = Object.values(checkedOrders)
      .flatMap(Object.keys);

      Modal.confirm({
        title: 'Cancelar pedido(s)',
        content: `Deseja realmente cancelar o(s) ${countCheckedOrders} pedido(s)?`,
        okText: 'Sim',
        cancelText: 'Não',
        onOk: () => {
          beforeCancelMulti();
          cancelMultiple(ordersIds)
            .then((response) => {
              message.success('Pedido(s) cancelado(s) com sucesso!');
              onSuccessCancelMulti(response);
            })
            .catch((err) => {
              message.error(`${err.data?.message || 'Não foi possível cancelar o(s) pedido(s), tente novamente mais tarde.'}`);
            })
            .finally(() => {
              onFinnalyCancelMulti()
            });
        }
      })
  }

  const renderItem = ({ item, index }) => {
    const key = item.id;
    if (!expanded[key]) {
      expanded[key] = {}
    }

    return (
      <>
        {
          expanded[key].expanded ?
            (
            <>
              <DocumentItemExpanded
                item={item}
                onClickOrder={onClickOrder}
                onSavingOrderNumber={onSavingOrderNumber}
                onEditingNumberOrder={onEditingNumberOrder}
                editing={editing}
                showModal={showModal}
                onPress={() => {
                  expanded[key].expanded = !expanded[key].expanded
                  setExpanded({ ...expanded })
                }} 
                propsMultiCancel={{
                  marginList: 10,
                  hideConfirmButton: true,
                  hideSelectAll: true,
                  multiCancel,
                  onClickSelectOrders,
                  showMultiCancel: false,
                  checkedOrders,
                  onCheckOrder,
                  countCheckedOrders,
                  onCheckAllOrders,
                }}/>
              </>
              )
            :
            <DocumentItem
              item={item}
              onPress={() => {
                expanded[key].expanded = !expanded[key].expanded
                setExpanded({ ...expanded })
              }} />
        }
      </>
    )
  }

  const onHandleSendFile = async () => {
    if (!document_selected) {
      message.error('Nenhum PDF selecionado!')
      return
    }

    try {
      setProcessingPdf(true);
      const document = await upload(document_selected, setProgressFile)

      setDocuments([...documents, document])
      setShowModal(false);
      setDocumentSelected(null);

      message.success('Arquivo enviado com sucesso!')
    } catch (e) {
      message.warn('Não foi possível enviar o pdf!')
    } finally {
      setProcessingPdf(false);
    }
  }

  const keyExtractor = (item) => item.id.toString();

  const documents_group = _.groupBy(_.sortBy(documents, (doc) => {
    return (new Date(doc.created_at).getTime()) * -1;
  }), (document) => {
    return moment(document.created_at).format('DD/MM/YYYY')
  })

  return (
    <Layout>
      <Header title={'Documentos'} />

      <Content style={{
        padding: '0 20px',
        marginTop: hideToolbar() ? 0 : 70,
        paddingLeft: 0,
        paddingRight: 0,
      }}>
        <Spin tip={'Carregando...'} spinning={loading || loadingCancelOrders}>
          <View>
            {
              !Object.keys(documents_group).length && (
                <View style={{
                  marginVertical: 16,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <Empty description={'Nenhum PDF enviado até o momento'}>
                    <Button type="primary" onClick={refresh}>Atualizar</Button>
                  </Empty>
                </View>
              )
            }
            {(!loading && documents.length > 0) && <div style={{ position: 'sticky', top: '60px', background: '#f0f2f5', zIndex: 9, padding: '0px 10px 10px 10px' }}>
              {!multiCancel ?
                <Button 
                  size="large" 
                  type="link" 
                  style={{paddingLeft: '0px', marginTop: '12px', paddingBottom: '0px'}}
                  onClick={onClickSelectOrders}>
                  Selecionar pedidos
                </Button>
                :
                (
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px', paddingTop: '12px'}}>
                    <span>
                      <Button
                        size="large"
                        type="text"
                        style={{marginRight: '8px', width: '18px', height: '24px'}}
                        icon={<CloseOutlined />}
                        onClick={onClickSelectOrders}
                      ></Button>
                      {countCheckedOrders} item(s) selecionado(s)
                    </span>

                  </div>
                )}
              </div>
            }
            {
              Object.keys(documents_group).map((group, index) => {
                const item = documents_group[group]
                const date = item[0].created_at;

                return (
                  <View key={index}>
                    <View style={{
                      padding: '10px'
                    }}>
                      <h4>{`${moment(date).format('ddd')}, ${moment(date).format('LL')}`}</h4>
                    </View>

                    <FlatList
                      data={item}
                      ItemSeparatorComponent={
                        () => (
                          <View style={{
                            height: 2,
                            backgroundColor: color.grayContainer,
                          }}
                          />
                        )
                      }
                      keyExtractor={keyExtractor}
                      renderItem={renderItem}
                    />
                  </View>
                )
              })
            }
          </View>
        </Spin>
      </Content>

      {(multiCancel) && <div style={{ position: 'relative', display: 'flex' }}>  
        <Button
          size="large"
          type="primary" 
          disabled={loadingCancelOrders}
          style={{ bottom: '0px', position: 'fixed', width: '100%', zIndex: 10 }} 
          onClick={onSaveCancelMultiOrders}
          >
            Cancelar pedidos selecionados
        </Button>
      </div>}

      <ModalSendPDF
        document_selected={document_selected}
        setDocumentSelected={setDocumentSelected}
        show_modal={show_modal}
        progressFile={progressFile}
        processingPdf={processingPdf}
        setShowModal={setShowModal}
        onHandleSendFile={onHandleSendFile}
      />

      <Modal
        title="Você deseja realmente cancelar esse pedido?"
        visible={open_modal}
        cancelText={'Não'}
        okText={'Sim'}
        onOk={handleOk}
        onCancel={handleCancel}>
        <h2>
          {current_item_delete && <span> #{current_item_delete.reference}</span>}
        </h2>
      </Modal>

      <FloatingButton
        icon={<UploadOutlined style={{
          fontSize: 20,
          color: '#fff'
        }} />}
        action={() => {
          setShowModal(true)
        }}
      />
    </Layout>
  )
}

export default withRouter(Documents)
