import React from 'react'
import { withRouter } from 'react-router-dom';
import { TouchableOpacity } from 'react-native-web';
import { FilePdfOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import Profile from '../Screens/Profile'
import { Layout } from 'antd';
import { hideToolbar } from './../Services/Auth';
import SubTitle from './../Components/SubTitle';

const { Header } = Layout

const HeaderApp = ({history, title}) => {

  if (hideToolbar()) {
    return (
      <SubTitle hide_icon_back={true} title={title} history={history} />
    )
  }

  return (
    <Header className="header" style={{ position: 'fixed', zIndex: 2, width: '100%' }}>
      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        marginLeft: -20,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <div>
          <h1>
            E-PEDIDOS
          </h1>
        </div>

        <div style={{
          display: 'flex',
          flexDirection: 'row',
        }}>
          <TouchableOpacity>
            <ShoppingCartOutlined
              style={{ color: '#fff', fontSize: '20px', marginRight: 10 }}
              onClick={() => {
                history.push('/');
              }} />
          </TouchableOpacity>

          <TouchableOpacity>
            <FilePdfOutlined
              style={{ color: '#fff', fontSize: '20px', marginRight: 10 }}
              onClick={() => {
                history.push('/documents');
              }} />
          </TouchableOpacity>

          <Profile />
        </div>
      </div>
    </Header>
  )
}

export default withRouter(HeaderApp);
